import React, {memo, useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {animated as d, config, useSpring} from "react-spring";
import './style.scss'
import {useTkCart, useTkUser} from "../../../context/TkContext";
import {User, UserRole} from "../../../models/user";
import {tk_route_sign_in} from "../../../views/TkSignInView";
import {tk_route_sign_up} from "../../../views/TkSignUpView";
import {tk_route_account} from "../../../views/TkMyAccountView";
import {tk_route_my_orders} from "../../../views/TkMyOrdersView";
import {tk_route_root} from "../../../views/TkHomeView";
import DeviceStorage from "../../../utils/storage-utils";
import {tk_route_my_lists} from "../../../views/TkMyListsView";
import {tk_route_seller_list_manager_view} from "../../../views/TkSellerListsManagerView";


const MenuContext: React.FC<{
    isShow: boolean,
    setIsShow: (show: boolean) => void,
    logout: () => void,
    user?: User | null,
}> = memo(({isShow, setIsShow, user, logout}) => {
    const {isTypePro, isTypeBusiness, hasRole} = useTkUser()
    const {clearCart} = useTkCart()
    const [display, setDisplay] = useState('none');
    const [isAuthorizedListManager, setAuthorizedListManager] = useState(false);

    const contentProps = useSpring({
        opacity: isShow ? 1 : 0,
        marginTop: isShow ? 165 : 200,
        onRest: () => {
            if (!isShow) setDisplay('none');
        },
        onStart: () => {
            if (isShow) setDisplay('inline-block');
        },
        delay: isShow ? 0 : 200,
        config: {...config, duration: 150}
    });

    const doLogout = useCallback(async () => {
        await logout()
        clearCart();
    }, [logout, clearCart])

    useEffect(() => {
        hasRole(UserRole.PLENOBRAS_SELLER_LIST_MANAGER)
            .then(setAuthorizedListManager)
    }, [hasRole])

    return <d.div className='TkTopWelcome__context'
                  onMouseOver={() => setIsShow(true)}
                  onMouseOut={() => setIsShow(false)}
                  style={{
                      ...contentProps,
                      display: display
                  }}>

        {user ?
            <ul>
                <li><Link to={tk_route_account} title='Ir para sua conta'>Sua conta</Link></li>
                <li><Link to={tk_route_my_orders} title='Ver seus pedidos'>Pedidos</Link></li>
                {(isTypePro() || isTypeBusiness()) && <li><Link to={tk_route_my_lists} title='Ver suas listas pro'>Listas Pro</Link></li>}
                {isAuthorizedListManager && <li><Link to={tk_route_seller_list_manager_view()} title='Gerenciar listas'>Gerenciar listas</Link></li>}
                <li><a href='#logout' onClick={doLogout} title='Sair da conta'>Sair</a></li>
            </ul>
            :
            <>
                <Link className='TkTopWelcome__login' to={tk_route_sign_in} title='Entrar na Plenobras'>Entrar</Link>
                <div className='TkTopWelcome__sign-up'>Cliente Novo? <Link to={tk_route_sign_up}
                                                                           title='Faça seu cadastro'>Faça seu cadastro</Link>
                </div>
            </>
        }
    </d.div>
});

const TkTopWelcome: React.FC = () => {
    const [isOver, setIsOver] = useState(false);
    const {userAuth, logout, isAuth} = useTkUser();
    const __KEY_SHOW_LOGIN = '__KEY_SHOW_LOGIN';

    const doLogout = useCallback(() => {
        setIsOver(false);
        logout()
            .then(() => document.location.href = tk_route_root)
    }, [logout, setIsOver]);

    useEffect(() => {
        let lastShow = DeviceStorage.getObject(__KEY_SHOW_LOGIN);
        const now = new Date();

        if (lastShow) lastShow = new Date(lastShow.toString());
        else lastShow = now;

        let timer: any = null;
        let timer2: any = null;

        // @ts-ignore
        if (!isAuth() && (now.getTime() === lastShow.getTime() || now.getTime() - lastShow.getTime() > 120000)) {
            timer = setTimeout(() => {
                setIsOver(true);
            }, 1500);

            timer2 = setTimeout(() => {
                setIsOver(false);
            }, 6500);

            DeviceStorage.putObject(__KEY_SHOW_LOGIN, now);
        }

        return () => {
            clearTimeout(timer);
            clearTimeout(timer2);
        }
    }, [userAuth, isAuth]);

    const [route, label] = isAuth() ?
        // @ts-ignore
        [tk_route_account, userAuth.user.fullName]
        :
        [tk_route_sign_in, 'Faça seu login'];

    return <div className='TkTopWelcome'>
        <span>Olá,</span>
        <div>
            <Link to={route} className='TkTopWelcome__link ellipsis'
                  onMouseOver={() => setIsOver(a => !a)}
                  onMouseOut={() => setIsOver(a => !a)}>{label}</Link>
        </div>

        <MenuContext isShow={isOver}
                     setIsShow={setIsOver}
                     logout={doLogout}
                     user={userAuth?.user}/>
    </div>;
};

export default memo(TkTopWelcome);