import React, {FC, memo, useEffect, useState} from "react";

import "./style.scss";

import TkQuantityOptions from "../TkQuantityOptions";
import {ITkProductModel} from "../../../models/product";
import {MAX_BACKORDER, MAX_DROPDOWN_QUANTITY} from "../../../utils/constants";

const NON_INT_REGEX = /\D+/gi;

const TkQuantitySelect: FC<{
  initialQuantity?: number;
  product?: ITkProductModel;
  onChange?: (quantity: number) => void;
  disabled?: boolean;
}> = ({ initialQuantity, product, onChange, disabled }) => {
  const [quantity, setQuantity] = useState(initialQuantity || 0);
  const [showCustomQuantity, setShowCustomQuantity] = useState(false);

  useEffect(() => {
    checkShowCustomQuantity();
  }, []);

  const checkShowCustomQuantity = () => {
    if (quantity && product) {
      const isMoreThanStock = product.stock && quantity > product.stock;
      setShowCustomQuantity(
        isMoreThanStock || (quantity > MAX_DROPDOWN_QUANTITY && product.minimumSaleQuantity == 1)
      );
    }
  };

  const changeQuantity = (e: any) => {
    setShowCustomQuantity(e.target.value === "more");

    if (e.target.value !== "more") {
      const quantity = parseInt(e.target.value.replace(NON_INT_REGEX, ""));
      setQuantity(quantity);
      checkShowCustomQuantity();

      if (onChange) {
        onChange(quantity);
      }
    }
  };

  return (
    <div className="TkQuantitySelect">
      {showCustomQuantity ? (
        <input
          type="number"
          className="form-control"
          min={1}
          max={MAX_BACKORDER}
          value={quantity}
          disabled={disabled}
          onChange={(e) =>
            setQuantity(parseInt(e.target.value.replace(NON_INT_REGEX, "")))
          }
          onBlur={changeQuantity}
        />
      ) : (
        <select
          value={quantity}
          onChange={changeQuantity}
          className="form-control form-control-sm"
          disabled={disabled}
        >
          <TkQuantityOptions stock={product?.stock} controlMultiplicity={product?.controlMultiplicity} minimumSaleQuantity={product.minimumSaleQuantity}/>
        </select>
      )}
    </div>
  );
};

export default memo(TkQuantitySelect);
